.AddButtonPosition {
  display: flex;
  justify-content: flex-end;
  margin: 3%;
}
.AddButton {
  width: 16vw;
  height: 42px;
  background: #d3262a 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 6px;
  opacity: 1;
  text-transform: uppercase;
  border: none;
  color: #fff;
}



.Table {
  width: 98%;
  margin-left: 1%;
}
.tableHeader {
  font: normal normal 500 26px/20px Roboto;
  letter-spacing: 0;
  color: #143754;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  opacity: 1;
  width: 100vw;
}
.AddEditInstructor .MuiTypography-root.MuiTypography-h6 {
  text-align: center;
  font: normal normal 400 26px/20px Roboto;
  letter-spacing: 0;
  color: #143754;
  text-transform: uppercase;
  opacity: 1;
  width: 32vw;
}
.InstructorTopContent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
}
.InstructorLable {
  text-align: left;
  font: normal normal normal 15px/24px Roboto;
  letter-spacing: 0;
  color: #143754;
  padding-top: 1%;
}
.InstructorName {
  background: #f4f4f4 0 0 no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  border: none;
  height: 35px;
  padding-left: 10px;
  outline: none;
}
.instructorButton {
  display: flex;
  justify-content: center;
  margin-top: 4vh;
}
.InstructorCancle {
  color: #fff;
  border: none;
  width: 142px;
  height: 40px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 6px;
  font-size: 18px;
  margin-bottom: 2vh;
  background: #d3262a 0 0 no-repeat padding-box;
}
.InstructorSave {
  color: #fff;
  border: none;
  width: 142px;
  height: 40px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 6px;
  font-size: 18px;
  margin-bottom: 2vh;
  background: #0b76b8 0 0 no-repeat padding-box;
  margin-left: 20px;
}
.ViewEditTableButton {
  color: #fff;
  border: none;
  width: 100px;
  height: 30px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 6px;
  font-size: 18px;
  margin-bottom: 2vh;
  background: #0b76b8 0 0 no-repeat padding-box;
  margin-left: 20px;
}

.AddCoursesTextarea {
  background: #f4f4f4 0 0 no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  border: none;
  height: 35px;
  padding-left: 10px;
  padding-top: 5px;
  outline: none;
  width: 97%;
  height: 7hv;
}
.EditTableButton {
  width: 5vw;
  height: 30px;
  background: #d3262a 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 6px;
  opacity: 1;
  border: none;
  color: #fff;
}
.ViewTableButton {
  width: 4vw;
  height: 30px;
  background: #5c8fff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 6px;
  opacity: 1;
  border: none;
  color: #fff;
}
.ViewIconsButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3vw;
  height: 30px;
  background: #3991f7 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 6px;
  opacity: 1;
  border: none;
  color: #fff;
}
.ViewIconsButton :hover {
  width: 3vw;
  height: 30px;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 50%);
}
.EditIconsButton {
  width: 3vw;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d3262a 0 0 no-repeat padding-box;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  border-radius: 6px;
  opacity: 1;
  border: none;
  color: #fff;
}

.MuiPaper-elevation4{
  padding:10px !important;
}

.MuiButton-text{
  padding:0px 0px !important;
}

.EditIconsButton :hover {
  width: 2vw;
  height: 25px;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 30%);
}
.DashboardTopContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 4vh;
  margin-left: 1vw;
}
.DashboardContainer {
  width: 23%;
  height: 18vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.dashboardTotal {
  text-align: left;
  font: normal normal normal 18px/24px Segoe UI;
  letter-spacing: 0px;
  color: #8e8c92;
  opacity: 1;
}
.dashboardTotalNumber {
  text-align: center;
  font: normal normal bold 32px/43px Segoe UI;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.DashboardMiddleContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 2vh;
  margin-left: 1vw;
}
.DashboaedGraph1 {
  width: 25vw;
  height: 36vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 28px;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DashboaedGraph2 {
  width: 52vw;
  height: 36vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 28px;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
}
.DashboardBottomContainer {
  display: flex;
  justify-content: space-around;
  margin-top: 2vh;
  margin-left: 1vw;
}
.DashboaedUser {
  width: 38vw;
  height: 30vh;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 28px;
  opacity: 0.9;
  display: flex;
  justify-content: center;
  align-items: center;
}
._3vt7_Mh4hRCFbp__dFqBCI input {
  width: 8vw !important;
}
._2iA8p44d0WZ-WqRBGcAuEV {
  width: 15.7vw;
  background: #f4f4f4 0 0 no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  border: none !important;
  padding-left: 10px;
  outline: none;
}
.coursesModal ._2iA8p44d0WZ-WqRBGcAuEV {
  width: 95%;
  background: #f4f4f4 0 0 no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  border: none !important;
  padding-left: 10px;
  outline: none;
}

.PaymentHistory {
  font: normal normal 500 26px/20px Roboto;
  letter-spacing: 0;
  color: #143754;
  text-transform: uppercase;
  padding-top: 3%;
  padding-bottom: 3%;
  opacity: 1;
}
.MuiTableCell-body {
  text-align: left !important;
  padding: 6px !important;
}
.MUIDataTableHeadCell-contentWrapper-56 {
  justify-content: center;
}
/* .studentTable.MuiTableCell-body{
    padding: 6px !important;
} */

/* By Manish */

.FormInputContainer {
  width: 70%;
}

.MuiTableCell-head {
  text-align: left !important;
}

.deleteIcon {
  background-color: #d3262a;
  border: 1.5px solid #d3262a;
  color: #f8f8f8;
  border-radius: 0.2em;
  margin-left: 0.7em;
  padding: 0.16em 0.26em;
  transition: all 0.2s;
}

.MUIDataTableHeadCell-contentWrapper-56{
   display: table-cell !important;
   text-align: left !important;
}

.MUIDataTableHeadCell-fixedHeader-47{
  padding:6px !important;
}




.MUIDataTable-responsiveBase-5{
  padding:20px;
}

.deleteIcon:hover {
  background-color: #ffff;
  border: 1.5px solid #d3262a;
  color: #d3262a;
}

.upload-image-box > p {
  color: #7a7a7a;
  font-weight: 500;
  padding: 0.3em 0.8em;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  transition: all 0.2s;
  cursor: pointer;
}

.upload-image-box > p:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.image-container {
  width: 50%;
  max-width: 100%;
  max-height: 16em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container > span {
  position: absolute;
  margin-top: 1em;
  top: -12%;
  right: 12%;
}

.image-container > span > svg {
  cursor: pointer;
  color: #575757;
}

.image-container > img {
  width: 45%;
  max-width: 45%;
  max-height: 100%;
}
